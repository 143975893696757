var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"col-lg-8 col-12"},[(_vm.loading)?[_c('lottie-animation',{attrs:{"animationData":_vm.animationData}})]:[(_vm.isLast && _vm.items.content.length == 0)?_c('div',{staticClass:"empty-state"},[_c('img',{attrs:{"src":"assets/images/empty-leader.png"}}),_vm._m(0)]):_c('div',[(_vm.currentUser)?_c('div',{staticClass:"d-flex align-items-center justify-content-center p-4 flex-column"},[_c('div',{staticClass:"tab-tournament"},[_c('button',{staticClass:"tab-detail",class:{ active: _vm.isLast },attrs:{"type":"button"},on:{"click":function($event){return _vm.getItems(true)}}},[_vm._v(" Yeni Turnuva ")]),_c('button',{staticClass:"tab-detail",class:{ active: !_vm.isLast },attrs:{"type":"button"},on:{"click":function($event){return _vm.getItems(false)}}},[_vm._v(" Önceki Turnuva ")])])]):_vm._e(),(_vm.items)?_c('div',{staticClass:"pink-background-container outer-container d-flex align-items-center justify-content-around"},[_c('div',{staticClass:"box"}),_c('div',{staticClass:"box1"}),_c('div',{staticClass:"absolute winner-container"},[_c('div',{staticClass:"second"},[_c('div',{staticClass:"mini-icon text-white d-flex align-items-center justify-content-center"},[_vm._v(" 2 ")]),(_vm.items.content[1].isPictureActive)?_c('img',{staticClass:"profile-photo relative",attrs:{"width":"100%","height":"100%","src":_vm.items.content[1].isPictureActive &&
                  _vm.mediaBase +
                    '/images/avatars/' +
                    _vm.items.content[1].pictureName}}):_c('div',{staticClass:"profile-photo relative bg-no-image"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.items.content[1].firstName))+" ")]),_c('div',{staticClass:"winners-name"},[_vm._v(" "+_vm._s(_vm.items.content[1].firstName)+" "+_vm._s(_vm.items.content[1].lastName)+" ")]),_c('div',{staticClass:"cup-container"},[_c('img',{attrs:{"src":"/images/mobile/cup.svg","width":"16px","height":"16px"}}),_c('span',{staticClass:"cup-text"},[_vm._v(_vm._s(_vm.formatNumber(_vm.items?.content[1].cupQuantity))+" Kupa")])])]),_c('div',{staticClass:"first"},[_c('div',{staticClass:"mini-icon-first text-white d-flex align-items-center justify-content-center"},[_vm._v(" 1 ")]),_c('div',[(_vm.items.content[0].isPictureActive)?_c('img',{staticClass:"profile-photo-first relative",attrs:{"width":"100%","height":"100%","src":_vm.items.content[0].isPictureActive &&
                    _vm.mediaBase +
                      '/images/avatars/' +
                      _vm.items.content[0].pictureName}}):_c('div',{staticClass:"profile-photo-first bg-no-image"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.items.content[0].firstName))+" ")])]),_c('div',{staticClass:"winners-name"},[_vm._v(" "+_vm._s(_vm.items.content[0].firstName)+" "+_vm._s(_vm.items.content[0].lastName)+" ")]),_c('div',{staticClass:"cup-container"},[_c('img',{attrs:{"src":"/images/mobile/cup.svg","width":"16px","height":"16px"}}),_c('span',{staticClass:"cup-text"},[_vm._v(_vm._s(_vm.formatNumber(_vm.items.content[0].cupQuantity))+" Kupa")])])]),_c('div',{staticClass:"third"},[_c('div',{staticClass:"mini-icon text-white d-flex align-items-center justify-content-center"},[_vm._v(" 3 ")]),_c('div',[(_vm.items.content[2].isPictureActive)?_c('img',{staticClass:"profile-photo relative",attrs:{"width":"100%","height":"100%","src":_vm.items.content[2].isPictureActive &&
                    _vm.mediaBase +
                      '/images/avatars/' +
                      _vm.items.content[2].pictureName}}):_c('div',{staticClass:"profile-photo relative bg-no-image"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.items.content[2].firstName))+" ")])]),_c('div',{staticClass:"winners-name"},[_vm._v(" "+_vm._s(_vm.items.content[2].firstName)+" "+_vm._s(_vm.items.content[2].lastName)+" ")]),_c('div',{staticClass:"cup-container"},[_c('img',{attrs:{"src":"/images/mobile/cup.svg","width":"16px","height":"16px"}}),_c('span',{staticClass:"cup-text"},[_vm._v(_vm._s(_vm.formatNumber(_vm.items.content[2].cupQuantity))+" Kupa")])])])])]):_vm._e(),_c('div',[_c('div',{staticClass:"other-items-container"},_vm._l((_vm.items?.content?.slice(3)),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-items-center justify-content-between user-container"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"queue"},[_vm._v(_vm._s(index + 4))]),_c('div',{staticClass:"d-flex align-items-center",style:({ gap: '8px' })},[(item?.avatar)?_c('img',{staticClass:"user-avatar",attrs:{"width":"100%","height":"100%","src":item?.avatar &&
                      _vm.mediaBase + '/images/avatars/' + item?.avatar}}):_c('div',{staticClass:"bg-no-image-queue user-avatar"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item?.firstName))+" ")]),_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])])]),_c('div',{staticClass:"cup-container border"},[_c('img',{attrs:{"src":"/images/mobile/cup.svg","width":"16px","height":"16px"}}),_c('span',{staticClass:"cup-text"},[_vm._v(_vm._s(_vm.formatNumber(item.cupQuantity))+" Kupa")])])])}),0)]),_c('div',{staticClass:"current-user d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"queue text-white"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.currentUser?.memberRank))+" ")]),_c('div',{staticClass:"d-flex align-items-center",style:({ gap: '8px' })},[(_vm.userLeadership?.avatar)?_c('img',{staticClass:"user-avatar",attrs:{"src":_vm.userLeadership.avatar
                    ? _vm.mediaBase + '/images/avatars/' + _vm.userLeadership.avatar
                    : _vm.defaultAvatar,"width":"100%","height":"100%","alt":_vm.currentUser?.firstName + ' ' + _vm.currentUser?.lastName}}):_c('div',{staticClass:"bg-no-image-queue user-avatar"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.currentUser?.firstName))+" ")]),_c('div',{staticClass:"user-name text-white"},[_vm._v(" "+_vm._s(_vm.currentUser?.firstName)+" "+_vm._s(_vm.currentUser?.lastName)+" ")])])]),_c('div',{staticClass:"cup-container border"},[_c('img',{attrs:{"src":"/images/mobile/cup.svg","width":"16px","height":"16px"}}),_c('span',{staticClass:"cup-text"},[_vm._v(_vm._s(_vm.currentUser?.cupQuantity))])])])])]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"empty-content"},[_vm._v(" Liderlik oyunları yakında "),_c('br'),_vm._v("aramızda olacak ")])
}]

export { render, staticRenderFns }